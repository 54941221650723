const { CODES, DEFAULT } = require('../../shared/constants/country-iso');

class Customer {
  constructor({
    id,
    ecommerceId,
    name,
    phoneNumber,
    email,
    level,
    active,
    points,
    currentCountry,
    pointsByCountry,
    lastOrdersDateUpdatedByCountry,
    lastOrdersDateUpdated,
    lastAppointmentOrdersDateUpdated,
  }) {
    this.id = id;
    this.ecommerceId = ecommerceId;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.level = level;
    this.active = active;
    this.points = points;
    this.currentCountry = currentCountry;
    this.pointsByCountry = pointsByCountry;
    this.lastOrdersDateUpdatedByCountry = lastOrdersDateUpdatedByCountry;
    this.lastOrdersDateUpdated = lastOrdersDateUpdated;
    this.lastAppointmentOrdersDateUpdated = lastAppointmentOrdersDateUpdated;
    this.countryName = this.countryData().countryName
  }
  pointsForCountryIndex(countryIso) {
    return this.pointsByCountry.findIndex(points => points.countryIso === countryIso);
  }

  pointsForCountry(countryIso) {
    const pointsFound = this.pointsByCountry.find(points => points.countryIso === countryIso);
    if (!pointsFound) return 0;
    return pointsFound.points;
  }

  pointForCustomerCurrentCountry() {
    const pointsFound = this.pointsByCountry.find(points => points.countryIso === this.currentCountry);
    if (!pointsFound) return 0;
    return pointsFound.points;
  }

  lastOrderUpdateForCountryIndex(countryIso) {
    return this.lastOrdersDateUpdatedByCountry.findIndex(points => points.countryIso === countryIso);
  }

  get lastOrderUpdate() { //LEGACY CODE NEED THIS FOR APPOINTMENTS POINT UPDATES
    const typeOfLastOrdersDateUpdated = typeof this.lastOrdersDateUpdated === 'number';
    const typeOfLastAppointmentOrdersDateUpdated = typeof this.lastAppointmentOrdersDateUpdated === 'number';
    if (typeOfLastOrdersDateUpdated && !typeOfLastAppointmentOrdersDateUpdated) return this.lastOrdersDateUpdated;
    if (!typeOfLastOrdersDateUpdated && typeOfLastAppointmentOrdersDateUpdated) return this.lastAppointmentOrdersDateUpdated;
    if (this.lastOrdersDateUpdated > this.lastAppointmentOrdersDateUpdated) return this.lastOrdersDateUpdated;
    return this.lastAppointmentOrdersDateUpdated;
  }

  countryData() {
    const data = CODES[this.currentCountry || DEFAULT];
    return data;
  }
}

export default Customer;
