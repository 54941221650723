import productParser from "./parse-to-product";

const parseToProducts = (data) => {

  return {
    itemCount: data.itemCount,
    pageCount: data.pageCount,
    pages: data.pages,
    products: (data.products || []).map((product) => productParser.parseToProduct(product)),
  };
};

export default {
  parseToProducts
}
