module.exports = {
  DEFAULT: "BO",
  CODES  : {
    'AD': {
      'countryCode' : 'AD',
      'countryName' : 'Andorra',
      'currencyCode': 'EUR'
    },
    'AE': {
      'countryCode' : 'AE',
      'countryName' : 'United Arab Emirates',
      'currencyCode': 'AED'
    },
    'AF': {
      'countryCode' : 'AF',
      'countryName' : 'Afghanistan',
      'currencyCode': 'AFN'
    },
    'AG': {
      'countryCode' : 'AG',
      'countryName' : 'Antigua and Barbuda',
      'currencyCode': 'XCD'
    },
    'AI': {
      'countryCode' : 'AI',
      'countryName' : 'Anguilla',
      'currencyCode': 'XCD'
    },
    'AL': {
      'countryCode' : 'AL',
      'countryName' : 'Albania',
      'currencyCode': 'ALL'
    },
    'AM': {
      'countryCode' : 'AM',
      'countryName' : 'Armenia',
      'currencyCode': 'AMD'
    },
    'AO': {
      'countryCode' : 'AO',
      'countryName' : 'Angola',
      'currencyCode': 'AOA'
    },
    'AQ': {
      'countryCode' : 'AQ',
      'countryName' : 'Antarctica',
      'currencyCode': ''
    },
    'AR': {
      'countryCode' : 'AR',
      'countryName' : 'Argentina',
      'currencyCode': 'ARS'
    },
    'AS': {
      'countryCode' : 'AS',
      'countryName' : 'American Samoa',
      'currencyCode': 'USD'
    },
    'AT': {
      'countryCode' : 'AT',
      'countryName' : 'Austria',
      'currencyCode': 'EUR'
    },
    'AU': {
      'countryCode' : 'AU',
      'countryName' : 'Australia',
      'currencyCode': 'AUD'
    },
    'AW': {
      'countryCode' : 'AW',
      'countryName' : 'Aruba',
      'currencyCode': 'AWG'
    },
    'AX': {
      'countryCode' : 'AX',
      'countryName' : 'Åland',
      'currencyCode': 'EUR'
    },
    'AZ': {
      'countryCode' : 'AZ',
      'countryName' : 'Azerbaijan',
      'currencyCode': 'AZN'
    },
    'BA': {
      'countryCode' : 'BA',
      'countryName' : 'Bosnia and Herzegovina',
      'currencyCode': 'BAM'
    },
    'BB': {
      'countryCode' : 'BB',
      'countryName' : 'Barbados',
      'currencyCode': 'BBD'
    },
    'BD': {
      'countryCode' : 'BD',
      'countryName' : 'Bangladesh',
      'currencyCode': 'BDT'
    },
    'BE': {
      'countryCode' : 'BE',
      'countryName' : 'Belgium',
      'currencyCode': 'EUR'
    },
    'BF': {
      'countryCode' : 'BF',
      'countryName' : 'Burkina Faso',
      'currencyCode': 'XOF'
    },
    'BG': {
      'countryCode' : 'BG',
      'countryName' : 'Bulgaria',
      'currencyCode': 'BGN'
    },
    'BH': {
      'countryCode' : 'BH',
      'countryName' : 'Bahrain',
      'currencyCode': 'BHD'
    },
    'BI': {
      'countryCode' : 'BI',
      'countryName' : 'Burundi',
      'currencyCode': 'BIF'
    },
    'BJ': {
      'countryCode' : 'BJ',
      'countryName' : 'Benin',
      'currencyCode': 'XOF'
    },
    'BL': {
      'countryCode' : 'BL',
      'countryName' : 'Saint Barthélemy',
      'currencyCode': 'EUR'
    },
    'BM': {
      'countryCode' : 'BM',
      'countryName' : 'Bermuda',
      'currencyCode': 'BMD'
    },
    'BN': {
      'countryCode' : 'BN',
      'countryName' : 'Brunei',
      'currencyCode': 'BND'
    },
    'BO': {
      'countryCode'  : 'BO',
      'countryName'  : 'Bolivia',
      'currencyCode' : 'BOB',
      'localCurrency': 'Bs'
    },
    'BQ': {
      'countryCode' : 'BQ',
      'countryName' : 'Bonaire',
      'currencyCode': 'USD'
    },
    'BR': {
      'countryCode' : 'BR',
      'countryName' : 'Brazil',
      'currencyCode': 'BRL'
    },
    'BS': {
      'countryCode' : 'BS',
      'countryName' : 'Bahamas',
      'currencyCode': 'BSD'
    },
    'BT': {
      'countryCode' : 'BT',
      'countryName' : 'Bhutan',
      'currencyCode': 'BTN'
    },
    'BV': {
      'countryCode' : 'BV',
      'countryName' : 'Bouvet Island',
      'currencyCode': 'NOK'
    },
    'BW': {
      'countryCode' : 'BW',
      'countryName' : 'Botswana',
      'currencyCode': 'BWP'
    },
    'BY': {
      'countryCode' : 'BY',
      'countryName' : 'Belarus',
      'currencyCode': 'BYR'
    },
    'BZ': {
      'countryCode' : 'BZ',
      'countryName' : 'Belize',
      'currencyCode': 'BZD'
    },
    'CA': {
      'countryCode' : 'CA',
      'countryName' : 'Canada',
      'currencyCode': 'CAD'
    },
    'CC': {
      'countryCode' : 'CC',
      'countryName' : 'Cocos [Keeling] Islands',
      'currencyCode': 'AUD'
    },
    'CD': {
      'countryCode' : 'CD',
      'countryName' : 'Democratic Republic of the Congo',
      'currencyCode': 'CDF'
    },
    'CF': {
      'countryCode' : 'CF',
      'countryName' : 'Central African Republic',
      'currencyCode': 'XAF'
    },
    'CG': {
      'countryCode' : 'CG',
      'countryName' : 'Republic of the Congo',
      'currencyCode': 'XAF'
    },
    'CH': {
      'countryCode' : 'CH',
      'countryName' : 'Switzerland',
      'currencyCode': 'CHF'
    },
    'CI': {
      'countryCode' : 'CI',
      'countryName' : 'Ivory Coast',
      'currencyCode': 'XOF'
    },
    'CK': {
      'countryCode' : 'CK',
      'countryName' : 'Cook Islands',
      'currencyCode': 'NZD'
    },
    'CL': {
      'countryCode' : 'CL',
      'countryName' : 'Chile',
      'currencyCode': 'CLP'
    },
    'CM': {
      'countryCode' : 'CM',
      'countryName' : 'Cameroon',
      'currencyCode': 'XAF'
    },
    'CN': {
      'countryCode' : 'CN',
      'countryName' : 'China',
      'currencyCode': 'CNY'
    },
    'CO': {
      'countryCode' : 'CO',
      'countryName' : 'Colombia',
      'currencyCode': 'COP'
    },
    'CR': {
      'countryCode' : 'CR',
      'countryName' : 'Costa Rica',
      'currencyCode': 'CRC'
    },
    'CU': {
      'countryCode' : 'CU',
      'countryName' : 'Cuba',
      'currencyCode': 'CUP'
    },
    'CV': {
      'countryCode' : 'CV',
      'countryName' : 'Cape Verde',
      'currencyCode': 'CVE'
    },
    'CW': {
      'countryCode' : 'CW',
      'countryName' : 'Curacao',
      'currencyCode': 'ANG'
    },
    'CX': {
      'countryCode' : 'CX',
      'countryName' : 'Christmas Island',
      'currencyCode': 'AUD'
    },
    'CY': {
      'countryCode' : 'CY',
      'countryName' : 'Cyprus',
      'currencyCode': 'EUR'
    },
    'CZ': {
      'countryCode' : 'CZ',
      'countryName' : 'Czechia',
      'currencyCode': 'CZK'
    },
    'DE': {
      'countryCode' : 'DE',
      'countryName' : 'Germany',
      'currencyCode': 'EUR'
    },
    'DJ': {
      'countryCode' : 'DJ',
      'countryName' : 'Djibouti',
      'currencyCode': 'DJF'
    },
    'DK': {
      'countryCode' : 'DK',
      'countryName' : 'Denmark',
      'currencyCode': 'DKK'
    },
    'DM': {
      'countryCode' : 'DM',
      'countryName' : 'Dominica',
      'currencyCode': 'XCD'
    },
    'DO': {
      'countryCode' : 'DO',
      'countryName' : 'Dominican Republic',
      'currencyCode': 'DOP'
    },
    'DZ': {
      'countryCode' : 'DZ',
      'countryName' : 'Algeria',
      'currencyCode': 'DZD'
    },
    'EC': {
      'countryCode' : 'EC',
      'countryName' : 'Ecuador',
      'currencyCode': 'USD'
    },
    'EE': {
      'countryCode' : 'EE',
      'countryName' : 'Estonia',
      'currencyCode': 'EUR'
    },
    'EG': {
      'countryCode' : 'EG',
      'countryName' : 'Egypt',
      'currencyCode': 'EGP'
    },
    'EH': {
      'countryCode' : 'EH',
      'countryName' : 'Western Sahara',
      'currencyCode': 'MAD'
    },
    'ER': {
      'countryCode' : 'ER',
      'countryName' : 'Eritrea',
      'currencyCode': 'ERN'
    },
    'ES': {
      'countryCode' : 'ES',
      'countryName' : 'Spain',
      'currencyCode': 'EUR'
    },
    'ET': {
      'countryCode' : 'ET',
      'countryName' : 'Ethiopia',
      'currencyCode': 'ETB'
    },
    'FI': {
      'countryCode' : 'FI',
      'countryName' : 'Finland',
      'currencyCode': 'EUR'
    },
    'FJ': {
      'countryCode' : 'FJ',
      'countryName' : 'Fiji',
      'currencyCode': 'FJD'
    },
    'FK': {
      'countryCode' : 'FK',
      'countryName' : 'Falkland Islands',
      'currencyCode': 'FKP'
    },
    'FM': {
      'countryCode' : 'FM',
      'countryName' : 'Micronesia',
      'currencyCode': 'USD'
    },
    'FO': {
      'countryCode' : 'FO',
      'countryName' : 'Faroe Islands',
      'currencyCode': 'DKK'
    },
    'FR': {
      'countryCode' : 'FR',
      'countryName' : 'France',
      'currencyCode': 'EUR'
    },
    'GA': {
      'countryCode' : 'GA',
      'countryName' : 'Gabon',
      'currencyCode': 'XAF'
    },
    'GB': {
      'countryCode' : 'GB',
      'countryName' : 'United Kingdom',
      'currencyCode': 'GBP'
    },
    'GD': {
      'countryCode' : 'GD',
      'countryName' : 'Grenada',
      'currencyCode': 'XCD'
    },
    'GE': {
      'countryCode' : 'GE',
      'countryName' : 'Georgia',
      'currencyCode': 'GEL'
    },
    'GF': {
      'countryCode' : 'GF',
      'countryName' : 'French Guiana',
      'currencyCode': 'EUR'
    },
    'GG': {
      'countryCode' : 'GG',
      'countryName' : 'Guernsey',
      'currencyCode': 'GBP'
    },
    'GH': {
      'countryCode' : 'GH',
      'countryName' : 'Ghana',
      'currencyCode': 'GHS'
    },
    'GI': {
      'countryCode' : 'GI',
      'countryName' : 'Gibraltar',
      'currencyCode': 'GIP'
    },
    'GL': {
      'countryCode' : 'GL',
      'countryName' : 'Greenland',
      'currencyCode': 'DKK'
    },
    'GM': {
      'countryCode' : 'GM',
      'countryName' : 'Gambia',
      'currencyCode': 'GMD'
    },
    'GN': {
      'countryCode' : 'GN',
      'countryName' : 'Guinea',
      'currencyCode': 'GNF'
    },
    'GP': {
      'countryCode' : 'GP',
      'countryName' : 'Guadeloupe',
      'currencyCode': 'EUR'
    },
    'GQ': {
      'countryCode' : 'GQ',
      'countryName' : 'Equatorial Guinea',
      'currencyCode': 'XAF'
    },
    'GR': {
      'countryCode' : 'GR',
      'countryName' : 'Greece',
      'currencyCode': 'EUR'
    },
    'GS': {
      'countryCode' : 'GS',
      'countryName' : 'South Georgia and the South Sandwich Islands',
      'currencyCode': 'GBP'
    },
    'GT': {
      'countryCode' : 'GT',
      'countryName' : 'Guatemala',
      'currencyCode': 'GTQ'
    },
    'GU': {
      'countryCode' : 'GU',
      'countryName' : 'Guam',
      'currencyCode': 'USD'
    },
    'GW': {
      'countryCode' : 'GW',
      'countryName' : 'Guinea-Bissau',
      'currencyCode': 'XOF'
    },
    'GY': {
      'countryCode' : 'GY',
      'countryName' : 'Guyana',
      'currencyCode': 'GYD'
    },
    'HK': {
      'countryCode' : 'HK',
      'countryName' : 'Hong Kong',
      'currencyCode': 'HKD'
    },
    'HM': {
      'countryCode' : 'HM',
      'countryName' : 'Heard Island and McDonald Islands',
      'currencyCode': 'AUD'
    },
    'HN': {
      'countryCode' : 'HN',
      'countryName' : 'Honduras',
      'currencyCode': 'HNL'
    },
    'HR': {
      'countryCode' : 'HR',
      'countryName' : 'Croatia',
      'currencyCode': 'HRK'
    },
    'HT': {
      'countryCode' : 'HT',
      'countryName' : 'Haiti',
      'currencyCode': 'HTG'
    },
    'HU': {
      'countryCode' : 'HU',
      'countryName' : 'Hungary',
      'currencyCode': 'HUF'
    },
    'ID': {
      'countryCode' : 'ID',
      'countryName' : 'Indonesia',
      'currencyCode': 'IDR'
    },
    'IE': {
      'countryCode' : 'IE',
      'countryName' : 'Ireland',
      'currencyCode': 'EUR'
    },
    'IL': {
      'countryCode' : 'IL',
      'countryName' : 'Israel',
      'currencyCode': 'ILS'
    },
    'IM': {
      'countryCode' : 'IM',
      'countryName' : 'Isle of Man',
      'currencyCode': 'GBP'
    },
    'IN': {
      'countryCode' : 'IN',
      'countryName' : 'India',
      'currencyCode': 'INR'
    },
    'IO': {
      'countryCode' : 'IO',
      'countryName' : 'British Indian Ocean Territory',
      'currencyCode': 'USD'
    },
    'IQ': {
      'countryCode' : 'IQ',
      'countryName' : 'Iraq',
      'currencyCode': 'IQD'
    },
    'IR': {
      'countryCode' : 'IR',
      'countryName' : 'Iran',
      'currencyCode': 'IRR'
    },
    'IS': {
      'countryCode' : 'IS',
      'countryName' : 'Iceland',
      'currencyCode': 'ISK'
    },
    'IT': {
      'countryCode' : 'IT',
      'countryName' : 'Italy',
      'currencyCode': 'EUR'
    },
    'JE': {
      'countryCode' : 'JE',
      'countryName' : 'Jersey',
      'currencyCode': 'GBP'
    },
    'JM': {
      'countryCode' : 'JM',
      'countryName' : 'Jamaica',
      'currencyCode': 'JMD'
    },
    'JO': {
      'countryCode' : 'JO',
      'countryName' : 'Jordan',
      'currencyCode': 'JOD'
    },
    'JP': {
      'countryCode' : 'JP',
      'countryName' : 'Japan',
      'currencyCode': 'JPY'
    },
    'KE': {
      'countryCode' : 'KE',
      'countryName' : 'Kenya',
      'currencyCode': 'KES'
    },
    'KG': {
      'countryCode' : 'KG',
      'countryName' : 'Kyrgyzstan',
      'currencyCode': 'KGS'
    },
    'KH': {
      'countryCode' : 'KH',
      'countryName' : 'Cambodia',
      'currencyCode': 'KHR'
    },
    'KI': {
      'countryCode' : 'KI',
      'countryName' : 'Kiribati',
      'currencyCode': 'AUD'
    },
    'KM': {
      'countryCode' : 'KM',
      'countryName' : 'Comoros',
      'currencyCode': 'KMF'
    },
    'KN': {
      'countryCode' : 'KN',
      'countryName' : 'Saint Kitts and Nevis',
      'currencyCode': 'XCD'
    },
    'KP': {
      'countryCode' : 'KP',
      'countryName' : 'North Korea',
      'currencyCode': 'KPW'
    },
    'KR': {
      'countryCode' : 'KR',
      'countryName' : 'South Korea',
      'currencyCode': 'KRW'
    },
    'KW': {
      'countryCode' : 'KW',
      'countryName' : 'Kuwait',
      'currencyCode': 'KWD'
    },
    'KY': {
      'countryCode' : 'KY',
      'countryName' : 'Cayman Islands',
      'currencyCode': 'KYD'
    },
    'KZ': {
      'countryCode' : 'KZ',
      'countryName' : 'Kazakhstan',
      'currencyCode': 'KZT'
    },
    'LA': {
      'countryCode' : 'LA',
      'countryName' : 'Laos',
      'currencyCode': 'LAK'
    },
    'LB': {
      'countryCode' : 'LB',
      'countryName' : 'Lebanon',
      'currencyCode': 'LBP'
    },
    'LC': {
      'countryCode' : 'LC',
      'countryName' : 'Saint Lucia',
      'currencyCode': 'XCD'
    },
    'LI': {
      'countryCode' : 'LI',
      'countryName' : 'Liechtenstein',
      'currencyCode': 'CHF'
    },
    'LK': {
      'countryCode' : 'LK',
      'countryName' : 'Sri Lanka',
      'currencyCode': 'LKR'
    },
    'LR': {
      'countryCode' : 'LR',
      'countryName' : 'Liberia',
      'currencyCode': 'LRD'
    },
    'LS': {
      'countryCode' : 'LS',
      'countryName' : 'Lesotho',
      'currencyCode': 'LSL'
    },
    'LT': {
      'countryCode' : 'LT',
      'countryName' : 'Lithuania',
      'currencyCode': 'EUR'
    },
    'LU': {
      'countryCode' : 'LU',
      'countryName' : 'Luxembourg',
      'currencyCode': 'EUR'
    },
    'LV': {
      'countryCode' : 'LV',
      'countryName' : 'Latvia',
      'currencyCode': 'EUR'
    },
    'LY': {
      'countryCode' : 'LY',
      'countryName' : 'Libya',
      'currencyCode': 'LYD'
    },
    'MA': {
      'countryCode' : 'MA',
      'countryName' : 'Morocco',
      'currencyCode': 'MAD'
    },
    'MC': {
      'countryCode' : 'MC',
      'countryName' : 'Monaco',
      'currencyCode': 'EUR'
    },
    'MD': {
      'countryCode' : 'MD',
      'countryName' : 'Moldova',
      'currencyCode': 'MDL'
    },
    'ME': {
      'countryCode' : 'ME',
      'countryName' : 'Montenegro',
      'currencyCode': 'EUR'
    },
    'MF': {
      'countryCode' : 'MF',
      'countryName' : 'Saint Martin',
      'currencyCode': 'EUR'
    },
    'MG': {
      'countryCode' : 'MG',
      'countryName' : 'Madagascar',
      'currencyCode': 'MGA'
    },
    'MH': {
      'countryCode' : 'MH',
      'countryName' : 'Marshall Islands',
      'currencyCode': 'USD'
    },
    'MK': {
      'countryCode' : 'MK',
      'countryName' : 'Macedonia',
      'currencyCode': 'MKD'
    },
    'ML': {
      'countryCode' : 'ML',
      'countryName' : 'Mali',
      'currencyCode': 'XOF'
    },
    'MM': {
      'countryCode' : 'MM',
      'countryName' : 'Myanmar [Burma]',
      'currencyCode': 'MMK'
    },
    'MN': {
      'countryCode' : 'MN',
      'countryName' : 'Mongolia',
      'currencyCode': 'MNT'
    },
    'MO': {
      'countryCode' : 'MO',
      'countryName' : 'Macao',
      'currencyCode': 'MOP'
    },
    'MP': {
      'countryCode' : 'MP',
      'countryName' : 'Northern Mariana Islands',
      'currencyCode': 'USD'
    },
    'MQ': {
      'countryCode' : 'MQ',
      'countryName' : 'Martinique',
      'currencyCode': 'EUR'
    },
    'MR': {
      'countryCode' : 'MR',
      'countryName' : 'Mauritania',
      'currencyCode': 'MRO'
    },
    'MS': {
      'countryCode' : 'MS',
      'countryName' : 'Montserrat',
      'currencyCode': 'XCD'
    },
    'MT': {
      'countryCode' : 'MT',
      'countryName' : 'Malta',
      'currencyCode': 'EUR'
    },
    'MU': {
      'countryCode' : 'MU',
      'countryName' : 'Mauritius',
      'currencyCode': 'MUR'
    },
    'MV': {
      'countryCode' : 'MV',
      'countryName' : 'Maldives',
      'currencyCode': 'MVR'
    },
    'MW': {
      'countryCode' : 'MW',
      'countryName' : 'Malawi',
      'currencyCode': 'MWK'
    },
    'MX': {
      'countryCode' : 'MX',
      'countryName' : 'Mexico',
      'currencyCode': 'MXN'
    },
    'MY': {
      'countryCode' : 'MY',
      'countryName' : 'Malaysia',
      'currencyCode': 'MYR'
    },
    'MZ': {
      'countryCode' : 'MZ',
      'countryName' : 'Mozambique',
      'currencyCode': 'MZN'
    },
    'NA': {
      'countryCode' : 'NA',
      'countryName' : 'Namibia',
      'currencyCode': 'NAD'
    },
    'NC': {
      'countryCode' : 'NC',
      'countryName' : 'New Caledonia',
      'currencyCode': 'XPF'
    },
    'NE': {
      'countryCode' : 'NE',
      'countryName' : 'Niger',
      'currencyCode': 'XOF'
    },
    'NF': {
      'countryCode' : 'NF',
      'countryName' : 'Norfolk Island',
      'currencyCode': 'AUD'
    },
    'NG': {
      'countryCode' : 'NG',
      'countryName' : 'Nigeria',
      'currencyCode': 'NGN'
    },
    'NI': {
      'countryCode' : 'NI',
      'countryName' : 'Nicaragua',
      'currencyCode': 'NIO'
    },
    'NL': {
      'countryCode' : 'NL',
      'countryName' : 'Netherlands',
      'currencyCode': 'EUR'
    },
    'NO': {
      'countryCode' : 'NO',
      'countryName' : 'Norway',
      'currencyCode': 'NOK'
    },
    'NP': {
      'countryCode' : 'NP',
      'countryName' : 'Nepal',
      'currencyCode': 'NPR'
    },
    'NR': {
      'countryCode' : 'NR',
      'countryName' : 'Nauru',
      'currencyCode': 'AUD'
    },
    'NU': {
      'countryCode' : 'NU',
      'countryName' : 'Niue',
      'currencyCode': 'NZD'
    },
    'NZ': {
      'countryCode' : 'NZ',
      'countryName' : 'New Zealand',
      'currencyCode': 'NZD'
    },
    'OM': {
      'countryCode' : 'OM',
      'countryName' : 'Oman',
      'currencyCode': 'OMR'
    },
    'PA': {
      'countryCode' : 'PA',
      'countryName' : 'Panama',
      'currencyCode': 'PAB'
    },
    'PE': {
      'countryCode' : 'PE',
      'countryName' : 'Peru',
      'currencyCode': 'PEN'
    },
    'PF': {
      'countryCode' : 'PF',
      'countryName' : 'French Polynesia',
      'currencyCode': 'XPF'
    },
    'PG': {
      'countryCode' : 'PG',
      'countryName' : 'Papua New Guinea',
      'currencyCode': 'PGK'
    },
    'PH': {
      'countryCode' : 'PH',
      'countryName' : 'Philippines',
      'currencyCode': 'PHP'
    },
    'PK': {
      'countryCode' : 'PK',
      'countryName' : 'Pakistan',
      'currencyCode': 'PKR'
    },
    'PL': {
      'countryCode' : 'PL',
      'countryName' : 'Poland',
      'currencyCode': 'PLN'
    },
    'PM': {
      'countryCode' : 'PM',
      'countryName' : 'Saint Pierre and Miquelon',
      'currencyCode': 'EUR'
    },
    'PN': {
      'countryCode' : 'PN',
      'countryName' : 'Pitcairn Islands',
      'currencyCode': 'NZD'
    },
    'PR': {
      'countryCode' : 'PR',
      'countryName' : 'Puerto Rico',
      'currencyCode': 'USD'
    },
    'PS': {
      'countryCode' : 'PS',
      'countryName' : 'Palestine',
      'currencyCode': 'ILS'
    },
    'PT': {
      'countryCode' : 'PT',
      'countryName' : 'Portugal',
      'currencyCode': 'EUR'
    },
    'PW': {
      'countryCode' : 'PW',
      'countryName' : 'Palau',
      'currencyCode': 'USD'
    },
    'PY': {
      'countryCode'  : 'PY',
      'countryName'  : 'Paraguay',
      'currencyCode' : 'PYG',
      'localCurrency': 'Gs'
    },
    'QA': {
      'countryCode' : 'QA',
      'countryName' : 'Qatar',
      'currencyCode': 'QAR'
    },
    'RE': {
      'countryCode' : 'RE',
      'countryName' : 'Réunion',
      'currencyCode': 'EUR'
    },
    'RO': {
      'countryCode' : 'RO',
      'countryName' : 'Romania',
      'currencyCode': 'RON'
    },
    'RS': {
      'countryCode' : 'RS',
      'countryName' : 'Serbia',
      'currencyCode': 'RSD'
    },
    'RU': {
      'countryCode' : 'RU',
      'countryName' : 'Russia',
      'currencyCode': 'RUB'
    },
    'RW': {
      'countryCode' : 'RW',
      'countryName' : 'Rwanda',
      'currencyCode': 'RWF'
    },
    'SA': {
      'countryCode' : 'SA',
      'countryName' : 'Saudi Arabia',
      'currencyCode': 'SAR'
    },
    'SB': {
      'countryCode' : 'SB',
      'countryName' : 'Solomon Islands',
      'currencyCode': 'SBD'
    },
    'SC': {
      'countryCode' : 'SC',
      'countryName' : 'Seychelles',
      'currencyCode': 'SCR'
    },
    'SD': {
      'countryCode' : 'SD',
      'countryName' : 'Sudan',
      'currencyCode': 'SDG'
    },
    'SE': {
      'countryCode' : 'SE',
      'countryName' : 'Sweden',
      'currencyCode': 'SEK'
    },
    'SG': {
      'countryCode' : 'SG',
      'countryName' : 'Singapore',
      'currencyCode': 'SGD'
    },
    'SH': {
      'countryCode' : 'SH',
      'countryName' : 'Saint Helena',
      'currencyCode': 'SHP'
    },
    'SI': {
      'countryCode' : 'SI',
      'countryName' : 'Slovenia',
      'currencyCode': 'EUR'
    },
    'SJ': {
      'countryCode' : 'SJ',
      'countryName' : 'Svalbard and Jan Mayen',
      'currencyCode': 'NOK'
    },
    'SK': {
      'countryCode' : 'SK',
      'countryName' : 'Slovakia',
      'currencyCode': 'EUR'
    },
    'SL': {
      'countryCode' : 'SL',
      'countryName' : 'Sierra Leone',
      'currencyCode': 'SLL'
    },
    'SM': {
      'countryCode' : 'SM',
      'countryName' : 'San Marino',
      'currencyCode': 'EUR'
    },
    'SN': {
      'countryCode' : 'SN',
      'countryName' : 'Senegal',
      'currencyCode': 'XOF'
    },
    'SO': {
      'countryCode' : 'SO',
      'countryName' : 'Somalia',
      'currencyCode': 'SOS'
    },
    'SR': {
      'countryCode' : 'SR',
      'countryName' : 'Suriname',
      'currencyCode': 'SRD'
    },
    'SS': {
      'countryCode' : 'SS',
      'countryName' : 'South Sudan',
      'currencyCode': 'SSP'
    },
    'ST': {
      'countryCode' : 'ST',
      'countryName' : 'São Tomé and Príncipe',
      'currencyCode': 'STD'
    },
    'SV': {
      'countryCode' : 'SV',
      'countryName' : 'El Salvador',
      'currencyCode': 'USD'
    },
    'SX': {
      'countryCode' : 'SX',
      'countryName' : 'Sint Maarten',
      'currencyCode': 'ANG'
    },
    'SY': {
      'countryCode' : 'SY',
      'countryName' : 'Syria',
      'currencyCode': 'SYP'
    },
    'SZ': {
      'countryCode' : 'SZ',
      'countryName' : 'Swaziland',
      'currencyCode': 'SZL'
    },
    'TC': {
      'countryCode' : 'TC',
      'countryName' : 'Turks and Caicos Islands',
      'currencyCode': 'USD'
    },
    'TD': {
      'countryCode' : 'TD',
      'countryName' : 'Chad',
      'currencyCode': 'XAF'
    },
    'TF': {
      'countryCode' : 'TF',
      'countryName' : 'French Southern Territories',
      'currencyCode': 'EUR'
    },
    'TG': {
      'countryCode' : 'TG',
      'countryName' : 'Togo',
      'currencyCode': 'XOF'
    },
    'TH': {
      'countryCode' : 'TH',
      'countryName' : 'Thailand',
      'currencyCode': 'THB'
    },
    'TJ': {
      'countryCode' : 'TJ',
      'countryName' : 'Tajikistan',
      'currencyCode': 'TJS'
    },
    'TK': {
      'countryCode' : 'TK',
      'countryName' : 'Tokelau',
      'currencyCode': 'NZD'
    },
    'TL': {
      'countryCode' : 'TL',
      'countryName' : 'East Timor',
      'currencyCode': 'USD'
    },
    'TM': {
      'countryCode' : 'TM',
      'countryName' : 'Turkmenistan',
      'currencyCode': 'TMT'
    },
    'TN': {
      'countryCode' : 'TN',
      'countryName' : 'Tunisia',
      'currencyCode': 'TND'
    },
    'TO': {
      'countryCode' : 'TO',
      'countryName' : 'Tonga',
      'currencyCode': 'TOP'
    },
    'TR': {
      'countryCode' : 'TR',
      'countryName' : 'Turkey',
      'currencyCode': 'TRY'
    },
    'TT': {
      'countryCode' : 'TT',
      'countryName' : 'Trinidad and Tobago',
      'currencyCode': 'TTD'
    },
    'TV': {
      'countryCode' : 'TV',
      'countryName' : 'Tuvalu',
      'currencyCode': 'AUD'
    },
    'TW': {
      'countryCode' : 'TW',
      'countryName' : 'Taiwan',
      'currencyCode': 'TWD'
    },
    'TZ': {
      'countryCode' : 'TZ',
      'countryName' : 'Tanzania',
      'currencyCode': 'TZS'
    },
    'UA': {
      'countryCode' : 'UA',
      'countryName' : 'Ukraine',
      'currencyCode': 'UAH'
    },
    'UG': {
      'countryCode' : 'UG',
      'countryName' : 'Uganda',
      'currencyCode': 'UGX'
    },
    'UM': {
      'countryCode' : 'UM',
      'countryName' : 'U.S. Minor Outlying Islands',
      'currencyCode': 'USD'
    },
    'US': {
      'countryCode' : 'US',
      'countryName' : 'United States',
      'currencyCode': 'USD'
    },
    'UY': {
      'countryCode' : 'UY',
      'countryName' : 'Uruguay',
      'currencyCode': 'UYU'
    },
    'UZ': {
      'countryCode' : 'UZ',
      'countryName' : 'Uzbekistan',
      'currencyCode': 'UZS'
    },
    'VA': {
      'countryCode' : 'VA',
      'countryName' : 'Vatican City',
      'currencyCode': 'EUR'
    },
    'VC': {
      'countryCode' : 'VC',
      'countryName' : 'Saint Vincent and the Grenadines',
      'currencyCode': 'XCD'
    },
    'VE': {
      'countryCode' : 'VE',
      'countryName' : 'Venezuela',
      'currencyCode': 'VEF'
    },
    'VG': {
      'countryCode' : 'VG',
      'countryName' : 'British Virgin Islands',
      'currencyCode': 'USD'
    },
    'VI': {
      'countryCode' : 'VI',
      'countryName' : 'U.S. Virgin Islands',
      'currencyCode': 'USD'
    },
    'VN': {
      'countryCode' : 'VN',
      'countryName' : 'Vietnam',
      'currencyCode': 'VND'
    },
    'VU': {
      'countryCode' : 'VU',
      'countryName' : 'Vanuatu',
      'currencyCode': 'VUV'
    },
    'WF': {
      'countryCode' : 'WF',
      'countryName' : 'Wallis and Futuna',
      'currencyCode': 'XPF'
    },
    'WS': {
      'countryCode' : 'WS',
      'countryName' : 'Samoa',
      'currencyCode': 'WST'
    },
    'XK': {
      'countryCode' : 'XK',
      'countryName' : 'Kosovo',
      'currencyCode': 'EUR'
    },
    'YE': {
      'countryCode' : 'YE',
      'countryName' : 'Yemen',
      'currencyCode': 'YER'
    },
    'YT': {
      'countryCode' : 'YT',
      'countryName' : 'Mayotte',
      'currencyCode': 'EUR'
    },
    'ZA': {
      'countryCode' : 'ZA',
      'countryName' : 'South Africa',
      'currencyCode': 'ZAR'
    },
    'ZM': {
      'countryCode' : 'ZM',
      'countryName' : 'Zambia',
      'currencyCode': 'ZMW'
    },
    'ZW': {
      'countryCode' : 'ZW',
      'countryName' : 'Zimbabwe',
      'currencyCode': 'ZWL'
    }
  }
}
