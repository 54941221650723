import exchangeParser from "./parse-to-exchange";

const parseToExchanges = (data) => {

  return {
    itemCount: data.itemCount,
    pageCount: data.pageCount,
    pages: data.pages,
    exchanges: (data.exchanges || []).map((exchange) => exchangeParser.parseToExchange(exchange)),
  };
};

export default {
  parseToExchanges: parseToExchanges
}