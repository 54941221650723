const Bucket = window._env_.REACT_APP_S3_BUCKET;
const s3ImageFolder = window._env_.REACT_APP_S3_IMAGE_FOLDER;

const putToBucket = ({ client }) => (Key, ContentType, file) => {
  return new Promise((resolve, reject) => {
    const params = {
      Bucket,
      Key: `${s3ImageFolder}/${Key}`,
      ContentType,
      Body: file,
    };

    return client.upload(params, function (err, data) {
      if (err) {
        reject(err);
      }
      // If there is no errors we can send back the pre-signed PUT URL
      resolve(data.Location);
    });
  });
};

const deleteFromBucket = ({ client }) => (Key) => {
  return new Promise((resolve, reject) => {
    // Note Bucket is retrieved from the env variable above.
    const params = {
      Bucket,
      Key: Key,
    };

    // Note operation in this case is putObject
    client.deleteObject(params, function (err, data) {
      if (err) {
        reject(err);
      }

      // If there is no errors we can send back the pre-signed PUT URL
      resolve(data);
    });
  });
};

export default (client) =>
  class ImageService {
    uploadImage = putToBucket({ client });
    deleteImage = deleteFromBucket({ client });
  };
