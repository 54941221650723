import Configuration from "../../../model/configuration/configuration.model";

const parseToConfiguration = (gamificationConfiguration) => {
  const responseIsArray = Array.isArray(gamificationConfiguration);
  if(responseIsArray) {
    return gamificationConfiguration.map(configuration => new Configuration({
      walletExchangeRate    : configuration.walletExchangeRate,
      walletProductImageUrl  : configuration.walletProductImageUrl,
      countryIsoCode: configuration.countryIsoCode
    }))
  }
  return new Configuration({
    walletExchangeRate    : gamificationConfiguration.walletExchangeRate,
    walletProductImageUrl  : gamificationConfiguration.walletProductImageUrl,
    countryIsoCode: gamificationConfiguration.countryIsoCode
  });
};

export default {
  parseToConfiguration,
};
