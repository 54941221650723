const { CODES, DEFAULT } = require('../../shared/constants/country-iso');

class CustomerPointsByCountry {
  constructor({
    countryIso,
    points,
  }) {
    this.countryIso = countryIso;
    this.points = points;
    this.countryName = this.countryData().countryName
  }

  countryData() {
    const data = CODES[this.countryIso || DEFAULT];
    return data;
  }
}

export default CustomerPointsByCountry;
