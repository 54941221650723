import customerParser from "./parse-to-customer";

const parseToCustomers = (data) => {

  return {
    itemCount: data.itemCount,
    pageCount: data.pageCount,
    pages: data.pages,
    customers: (data.customers || []).map((customer) => customerParser.parseToCustomer(customer)),
  };
};

export default {
  parseToCustomers
}