import client from "./lib/request-manager";
import authHeader from "./lib/auth-header";
import isLegacyAdminUser from "./lib/verify-legacy-admin-user";

import AuthServiceFactory from "./auth.service";
import ProductServiceFactory from "./product.service";
import CustomerServiceFactory from "./customer.service";
import ExchangeServiceFactory from "./exchange.service";
import ConfigurationServiceFactory from "./configuration.service";


const AuthService = AuthServiceFactory(client, isLegacyAdminUser);
const ProductService = ProductServiceFactory(client, authHeader);
const CustomerService = CustomerServiceFactory(client, authHeader);
const ExchangeService = ExchangeServiceFactory(client, authHeader);
const ConfigurationService = ConfigurationServiceFactory(client, authHeader);


export default {
  authEnviospetService: new AuthService(),
  productEnviospetService: new ProductService(),
  customerEnviospetService: new CustomerService(),
  exchangeEnviospetService: new ExchangeService(),
  configurationEnviospetService: new ConfigurationService(),

};
