class ExchangeCustomer {
  constructor({
    id,
    ecommerceId,
    name,
    phoneNumber,
    email,
    level,
    active,
    pointsByCountry,
    //Borrar
    points,
  }) {
    this.id = id;
    this.ecommerceId = ecommerceId;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.level = level;
    this.active = active;
    this.pointsByCountry = pointsByCountry;

    //Borrar
    this.points = points;
  }
}

export default ExchangeCustomer;
