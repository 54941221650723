import CustomerPointLog from "../../../model/customer/customer.point.log.model";

const parseToCustomerPointLog = (enviosCustomerPointLog) => {
  return new CustomerPointLog({
    id: enviosCustomerPointLog.id,
    date: new Date(enviosCustomerPointLog.date),
    comment: enviosCustomerPointLog.comment,
    operation: enviosCustomerPointLog.operation,
    points: enviosCustomerPointLog.points,
    exchangeId: enviosCustomerPointLog.exchangeId,
    currentStatus: enviosCustomerPointLog.currentStatus,
    previousStatus: enviosCustomerPointLog.previousStatus,
    generatedBy: enviosCustomerPointLog.generatedBy,
    orderId: enviosCustomerPointLog.orderId,
    orderDate: enviosCustomerPointLog.orderDate,
    customerUpdated: enviosCustomerPointLog.customerUpdated,
    discountRate: enviosCustomerPointLog.discountRate,
    serviceName: enviosCustomerPointLog.serviceName,
    country: enviosCustomerPointLog.country || null
  });
};

export default {
  parseToCustomerPointLog,
};
