import STATES from "./exchange.states";
const { CODES, DEFAULT } = require('../../shared/constants/country-iso');

class Exchange {
  constructor({ id, customer, products, points, money, date, status, country, currency }) {
    this.id = id;
    this.customer = customer;
    this.products = products;
    this.points = points;
    this.money = money;
    this.date = date;
    this.status = status;
    this.country  = country;
    this.currency = currency;
    this.countryName = this.countryData().countryName
  }
  countryData() {
    const data = CODES[this.country || DEFAULT];
    return data;
  }
  static STATES = STATES
}

export default Exchange;
