import customerParser from "./parsers/parse-to-customer";
import customersParser from "./parsers/parse-to-customers";
import exchangeParser from "./parsers/parse-to-exchange";
import customerPointLogParser from "./parsers/parse-to-customer-log-point";

const ENDPOINT = "customers";

const getAll = ({ client, authHeader }) => async (limit, page, name) => {
  const response = await client.get(
    `/${ENDPOINT}?limit=${limit || ""}&page=${page || ""}&name=${name || ""}`,
    {
      headers: authHeader(),
    }
  );

  const customers = customersParser.parseToCustomers(response.data);

  return customers;
};

const get = ({ client, authHeader }) => async (id) => {
  const response = await client.get(`/${ENDPOINT}/${id}`, {
    headers: authHeader(),
  });

  const customer = customerParser.parseToCustomer(response.data);

  return customer;
};

const create = ({ client, authHeader }) => (data) => {
  return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
};

const update = ({ client, authHeader }) => (id, data) => {
  return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
};

const updateCustomerPoints = ({ client, authHeader }) => (id, data) => {
  return client.put(`/${ENDPOINT}/${id}/updatePoints`, data, { headers: authHeader() });
};

const remove = ({ client, authHeader }) => (id) => {
  return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
};

const removeAll = ({ client, authHeader }) => () => {
  return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
};

const unremove = ({ client, authHeader }) => (id) => {
  return client.put(`/${ENDPOINT}/${id}/undelete`, null, { headers: authHeader() });
};

const findByTitle = ({ client, authHeader }) => async (name) => {
  const response = await client.get(`/${ENDPOINT}?name=${name}`, {
    headers: authHeader(),
  });

  const customers = customersParser.parseToCustomers(response.data);

  return customers;
};

const getExchangesByCustomerId = ({ client, authHeader }) => async (id) => {

  const response = await client.get(
    `/${ENDPOINT}/${id}/exchanges`,
    {
      headers: authHeader(),
    }
  );

  const {data: exchanges} = response;

  const parsedExchanges = (exchanges || []).map(exchange => exchangeParser.parseToExchange(exchange))

  return parsedExchanges;
};

const getCustomerPointLogsByCustomerId = ({ client, authHeader }) => async (id) => {

  const response = await client.get(
    `/${ENDPOINT}/${id}/customerPointLogs`,
    {
      headers: authHeader(),
    }
  );

  const {data: customerPointLogs} = response;

  const parsedCustomerPointLogs = (customerPointLogs || []).map(log => customerPointLogParser.parseToCustomerPointLog(log))

  return parsedCustomerPointLogs;
};

const exchangePointsForMoney = ({ client, authHeader }) => (id, data, countryIsoCode) => {
  return client.put(`/${ENDPOINT}/${id}/exchange-money?countryIsoCode=${countryIsoCode}`, data, { headers: authHeader() });
};

export default (client, authHeader) =>
  class CustomerService {
    getAll = getAll({ client, authHeader });
    get = get({ client, authHeader });
    create = create({ client, authHeader });
    update = update({ client, authHeader });
    updateCustomerPoints = updateCustomerPoints({ client, authHeader });
    remove = remove({ client, authHeader });
    removeAll = removeAll({ client, authHeader });
    unremove = unremove({ client, authHeader });
    findByTitle = findByTitle({ client, authHeader });
    getExchangesByCustomerId = getExchangesByCustomerId({ client, authHeader });
    getCustomerPointLogsByCustomerId = getCustomerPointLogsByCustomerId({ client, authHeader });
    exchangePointsForMoney = exchangePointsForMoney({ client, authHeader })
  };
