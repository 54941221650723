import Exchange from "../../../model/exchange/exchange.model";
import ExchangeProduct from "../../../model/exchange/exchange.product.model";
import ExchangeCustomer from "../../../model/exchange/exchange.customer.model";
import ExchangeCustomerPointsByCountry from "../../../model/exchange/exchange.customer.points.by.country.model";

const DEFAULT_COUNTRY = "BO";
const DEFAULT_COUNTRY_ISO = {
  BOLIVIA: "BO",
  PARAGUAY: "PY",
  "": "BO",
  undefined: "BO",
  BO: "BO",
  PY: "PY"
};
const DEFAULT_CURRENCY = {
  BOLIVIA: "Bs",
  PARAGUAY: "Gs",
  "": "Bs",
  undefined: "Bs",
  BO: "Bs",
  PY: "Gs"
};

const parseToExchange = (enviospetExchange) => {
  // const exchangeSplitedId = enviospetExchange.id.split("#");

  let country = DEFAULT_COUNTRY;
  let currency = DEFAULT_CURRENCY.BO;

  if (enviospetExchange.country) {
    country = DEFAULT_COUNTRY_ISO[enviospetExchange.country.toUpperCase()];
    currency = DEFAULT_CURRENCY[enviospetExchange.country.toUpperCase()];
  }

  return new Exchange({
    id: enviospetExchange.id,
    customer: enviospetExchange.customer.id
      ? new ExchangeCustomer({
          id: enviospetExchange.customer.id,
          ecommerceId: enviospetExchange.customer.ecommerceId,
          name: enviospetExchange.customer.name,
          phoneNumber: enviospetExchange.customer.phoneNumber,
          email: enviospetExchange.customer.email,
          level: enviospetExchange.customer.level,
          active: enviospetExchange.customer.active,
          pointsByCountry: (
            enviospetExchange.customer.pointsByCountry || []
          ).map(
            (country) =>
              new ExchangeCustomerPointsByCountry({
                points: country.points,
                countryIsoCode: country.countryIsoCode,
              })
          ),
          //Borrar
          points: enviospetExchange.customer.points,
        })
      : enviospetExchange.customer,
    products: (enviospetExchange.products || []).map((product) => {
      return new ExchangeProduct({
        id: product.id,
        name: product.name,
        points: product.points,
        pointsWithMoney: product.pointsWithMoney,
        money: product.money,
        image: product.image,
        quantity: product.quantity,
        paidOnlyWithPoints: product.paidOnlyWithPoints,
      });
    }),
    points: enviospetExchange.points,
    money: enviospetExchange.money,
    date: new Date(enviospetExchange.date),
    status: enviospetExchange.status,
    country,
    currency,
  });
};

export default {
  parseToExchange,
};
