import productParser from './parsers/parse-to-product';
import productsParser from './parsers/parse-to-products';

const ENDPOINT = 'products';

const getAll = ({ client, authHeader }) => async (limit, page, name) => {
  const response = await client.get(`/${ENDPOINT}?limit=${limit || ""}&page=${page || ""}&name=${name || ""}`, {
    headers: authHeader(),
  });

  const products = productsParser.parseToProducts(response.data);

  return products;
};

const get = ({ client, authHeader }) => async (id) => {
  const response = await client.get(`/${ENDPOINT}/${id}`, { headers: authHeader() });

  const product = productParser.parseToProduct(response.data);

  return product;
};

const create = ({ client, authHeader }) => (data) => {
  return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
};

const update = ({ client, authHeader }) => (id, data) => {
  return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
};

const remove = ({ client, authHeader }) => (id) => {
  return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
};

const removeAll = ({ client, authHeader }) => () => {
  return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
};

const findByTitle = ({ client, authHeader }) => async (name) => {
  const response = await client.get(`/${ENDPOINT}?name=${name}`, { headers: authHeader() });

  const products = productsParser.parseToProducts(response.data);

  return products;
};

export default (client, authHeader) =>
  class ProductService {
    getAll = getAll({ client, authHeader });
    get = get({ client, authHeader });
    create = create({ client, authHeader });
    update = update({ client, authHeader });
    remove = remove({ client, authHeader });
    removeAll = removeAll({ client, authHeader });
    findByTitle = findByTitle({ client, authHeader });
  };
