import exchangeParser from "./parsers/parse-to-exchange";
import exchangesParser from "./parsers/parse-to-exchanges";

const ENDPOINT = "exchanges";

const getAll = ({ client, authHeader }) => async (limit, page, sortOrder) => {
  const sort = !!sortOrder.name
    ? `&sort=${sortOrder.direction === "asc" ? "" : "-"}${sortOrder.name}`
    : "";

  const response = await client.get(
    `/${ENDPOINT}?limit=${limit || ""}&page=${page || ""}${sort}`,
    {
      headers: authHeader(),
    }
  );

  const exchanges = exchangesParser.parseToExchanges(response.data);

  return exchanges;
};

const get = ({ client, authHeader }) => async (id) => {
  const response = await client.get(`/${ENDPOINT}/${id}`, {
    headers: authHeader(),
  });

  const exchange = exchangeParser.parseToExchange(response.data);

  return exchange;
};

const create = ({ client, authHeader }) => (data) => {
  return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
};

const update = ({ client, authHeader }) => (id, data) => {
  return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
};

const remove = ({ client, authHeader }) => (id) => {
  return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
};

const removeAll = ({ client, authHeader }) => () => {
  return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
};

const unremove = ({ client, authHeader }) => (id) => {
  return client.put(`/${ENDPOINT}/${id}/undelete`, null, {
    headers: authHeader(),
  });
};

export default (client, authHeader) =>
  class ExchangeService {
    getAll = getAll({ client, authHeader });
    get = get({ client, authHeader });
    create = create({ client, authHeader });
    update = update({ client, authHeader });
    remove = remove({ client, authHeader });
    removeAll = removeAll({ client, authHeader });
    unremove = unremove({ client, authHeader });
  };
