class Product {
  constructor({ id, name, points, pointsWithMoney, money, image, active, sort }) {
    this.id     = id;
    this.name   = name;
    this.points = points;
    this.money  = money;
    this.image  = image;
    this.active = active;
    this.sort   = sort;
    this.pointsWithMoney = pointsWithMoney;
  }
}

export default Product;
