import Customer from "../../../model/customer/customer.model";
import CustomerPointsByCountry from "../../../model/customer/customer.points.by.country.model";

const parseToCustomer = (enviosPetCustomer) => {
  return new Customer({
    id: enviosPetCustomer.id,
    ecommerceId: enviosPetCustomer.ecommerceId,
    name: enviosPetCustomer.name,
    phoneNumber: enviosPetCustomer.phoneNumber,
    email: enviosPetCustomer.email,
    level: enviosPetCustomer.level,
    active: enviosPetCustomer.active,
    points: enviosPetCustomer.points,
    currentCountry: enviosPetCustomer.currentCountry,
    pointsByCountry: (enviosPetCustomer.pointsByCountry || []).map(
      (country) =>
        new CustomerPointsByCountry({
          points: country.points,
          countryIso: country.countryIso,
        })
    ),
    lastOrdersDateUpdatedByCountry: enviosPetCustomer.lastOrdersDateUpdatedByCountry,
    lastOrdersDateUpdated: enviosPetCustomer.lastOrdersDateUpdated,
    lastAppointmentOrdersDateUpdated: enviosPetCustomer.lastAppointmentOrdersDateUpdated,
  });
};

export default {
  parseToCustomer,
};
