import enviospetServices from "./enviospet";
import s3Services from "./s3";

export default {
  authorizationEnviospetService: enviospetServices.authEnviospetService,
  productEnviospetService: enviospetServices.productEnviospetService,
  customerEnviospetService: enviospetServices.customerEnviospetService,
  exchangeEnviospetService: enviospetServices.exchangeEnviospetService,
  configurationEnviospetService: enviospetServices.configurationEnviospetService,
  imageS3Service: s3Services.imageS3Service,
};
