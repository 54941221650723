class ExchangeCustomerPointsByCountry {
  constructor({
    countryIsoCode,
    points,
  }) {
    this.countryIsoCode = countryIsoCode;
    this.points = points;
  }
}

export default ExchangeCustomerPointsByCountry;
