import Product from "../../../model/product/product.model";

const parseToProduct = (enviosPetProduct) => {
  return new Product({
    id    : enviosPetProduct.id,
    name  : enviosPetProduct.name,
    points: enviosPetProduct.points,
    money : enviosPetProduct.money,
    image : enviosPetProduct.image,
    active: enviosPetProduct.active,
    sort  : enviosPetProduct.sort,
    pointsWithMoney: enviosPetProduct.pointsWithMoney
  });
};

export default {
  parseToProduct,
};
