import configurationParser from "./parsers/parse-to-configuration";

const ENDPOINT = "config";

const get =
  ({ client, authHeader }) =>
  async (countryIsoCode) => {
    const response = await client.get(
      `${ENDPOINT}?countryIsoCode=${countryIsoCode}`,
      { headers: authHeader() }
    );

    const configuration = configurationParser.parseToConfiguration(
      response.data
    );

    return configuration;
  };

export default (client, authHeader) =>
  class ConfigurationService {
    get = get({ client, authHeader });
  };
