import AWS from "aws-sdk"; // Requiring AWS SDK.

// import config from "./aws-service-account";

// Configuring AWS
AWS.config = new AWS.Config({
  accessKeyId: window._env_.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: window._env_.REACT_APP_SECRET_ACCESS_KEY_ID,
  region: window._env_.REACT_APP_S3_REGION,
});

// Creating a S3 instance
const s3 = new AWS.S3();

export default s3;
