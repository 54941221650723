import STATES from "../exchange/exchange.states";
const { CODES, DEFAULT } = require("../../shared/constants/country-iso");

const OPERATIONS = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

const ORDER_PROVIDERS = {
  COMMERCE: "COMMERCE",
  APPOINTMENT: "APPOINTMENT",
};

const ORDER_PROVIDERS_ARRAY = Object.keys(ORDER_PROVIDERS);
class CustomerPointLog {
  constructor({
    id,
    date,
    comment,
    operation,
    points,
    generatedBy,
    exchangeId,
    currentStatus,
    previousStatus,
    orderId,
    orderDate,
    customerUpdated,
    discountRate,
    serviceName,
    country,
  }) {
    this.id = id;
    this.date = date;
    this.comment = comment;
    this.operation = OPERATIONS[operation];
    this.points = points;
    this.exchangeId = exchangeId;
    this.currentStatus = currentStatus;
    this.previousStatus = previousStatus;
    this.generatedBy = generatedBy;
    this.orderId = orderId;
    this.orderDate = orderDate;
    this.customerUpdated = customerUpdated;
    this.discountRate = discountRate;
    this.serviceName = serviceName;
    this.country = country;
    this.countryName = this.countryData().countryName;
    this.orderProviders = ORDER_PROVIDERS;
    this.orderProvidersArray = ORDER_PROVIDERS_ARRAY;
  }

  get isNewExchange() {
    return (
      (STATES[this.currentStatus] === STATES.PENDING ||
        STATES[this.currentStatus] === STATES.DONE) &&
      this.currentStatus === this.previousStatus
    );
  }

  get removingPoints() {
    if (this.operation === OPERATIONS.REMOVE) return true;
    return false;
  }

  get hasNoStatus() {
    if (!this.currentStatus && !this.previousStatus) return true;
    return false;
  }

  countryData() {
    if (!this.country) return CODES[DEFAULT];
    return CODES[this.country];
  }
}

export default CustomerPointLog;
