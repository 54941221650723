const { CODES, DEFAULT } = require('../../shared/constants/country-iso');

class Configuration {
  constructor({ walletExchangeRate, walletProductImageUrl, countryIsoCode }) {
    this.walletExchangeRate     = walletExchangeRate;
    this.walletProductImageUrl  = walletProductImageUrl;
    this.countryIsoCode         = countryIsoCode;
    this.countryName = this.countryData().countryName

  }
  countryData() {
    const data = CODES[this.countryIsoCode || DEFAULT];
    return data;
  }
}

export default Configuration;
