const login = ({ client, isLegacyAdminUser }) => (userName, password) => {
  return client
    .post("/auth/signin", {
      userName,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        const isLegacyUser = isLegacyAdminUser(response.data.accessToken);
        if (isLegacyUser) {
          throw new Error("Error al intentar iniciar sesión");
        }
        localStorage.setItem(
          "token",
          JSON.stringify(response.data.accessToken)
        );
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("token");
};

const register = ({ client }) => (username, email, password) => {
  return client.post("/auth/signup", {
    username,
    email,
    password,
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("token"));
};

const verifyLegacyUser = ({ isLegacyAdminUser }) => () => {
  const currentUser = JSON.parse(localStorage.getItem("token"));
  const isLegacyUser = isLegacyAdminUser(currentUser);
  if (isLegacyUser) {
    localStorage.removeItem("token");
    return true;
  }
  return false;
};

// const isLegacyAdminUser = ({ isLegacyAdminUser }) => () => {
//   const currentUser = JSON.parse(localStorage.getItem("token"));
//   const isLegacyUser = isLegacyAdminUser(currentUser);
//   if (isLegacyUser) {
//     localStorage.removeItem("token");
//     return null;
//   }
//   return currentUser;
// };

export default (client, isLegacyAdminUser) =>
  class ProductService {
    login = login({ client, isLegacyAdminUser });
    logout = logout;
    register = register({ client });
    getCurrentUser = getCurrentUser;
    verifyLegacyUser = verifyLegacyUser({ isLegacyAdminUser });
  };
