class ExchangeProduct {
  constructor({
    id,
    name,
    points,
    pointsWithMoney,
    money,
    image,
    quantity,
    paidOnlyWithPoints,
  }) {
    this.id = id;
    this.name = name;
    this.points = points;
    this.pointsWithMoney = pointsWithMoney;
    this.money = money;
    this.image = image;
    this.quantity = quantity;
    this.paidOnlyWithPoints = paidOnlyWithPoints;
  }
}

export default ExchangeProduct;
